<script setup lang="ts"></script>

<template>
  <div class="footer-hero">
    <div class="row container justify-between footer-hero__wrapper">
      <div class="col-lg-5 col-sm-6 col-xs-12">
        <div class="row footer-hero__list">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 my-auto">
            <q-icon name="telegram" size="sm" />
          </div>

          <div class="col-lg-11 col-md-4 col-sm-11 col-xs-11 footer-hero__item">
            <a
              class="footer-hero__link"
              href="https://t.me/riskpools1"
              target="_blank"
            >
              Telegram
            </a>
          </div>

          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 my-auto">
            <q-icon name="mail" size="sm" />
          </div>

          <div class="col-lg-11 col-md-4 col-sm-11 col-xs-10 footer-hero__item">
            Tech support:
            <a
              class="footer-hero__link"
              href="mailto:support_coin@riskpools.io"
            >
              support_coin@riskpools.io
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-sm-6 col-xs-12">
        <div class="row justify-sm-end content-end footer-hero__list">
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 my-auto">
            <q-icon name="people" size="sm" />
          </div>
          <div class="col-lg-11 col-md-4 col-sm-11 col-xs-11 footer-hero__item">
            Partnerships:
            <a class="footer-hero__link" href="mailto:offer@riskpools.io"
              >offer@riskpools.io</a
            >
          </div>
          <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 my-auto">
            <q-icon name="bi-twitter-x" size="sm" />
          </div>
          <div class="col-lg-11 col-md-4 col-sm-10 col-xs-10 footer-hero__item">
            <a class="footer-hero__link" target="_blank" href="https://twitter.com/RiskPools"
              >Twitter (X)</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./styles.module";
</style>
